import React from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import arrow from "../assets/arrow.svg";

const Faq = () => {
  const faqItems = [
    {
      question: "I operate my business outside of the U.S. Can I create a listing?",
      answer: "Absolutely. OBWS businesses span 10 different countries (& growing). We welcome Black entrepreneurs from all over the world.",
    },
    {
      question: "Is Official Black Wall Street only for brick and mortar businesses?",
      answer: "No, Official Black Wall Street supports all types of businesses, including online, service-based, and brick-and-mortar businesses. Our platform is designed to help Black entrepreneurs across various industries grow and thrive, regardless of their business model.",
    },
    {
      question: "My business isn't officially established yet. Should I join?",
      answer: "Absolutely! Whether you're still in the planning stages or just getting started, joining Official Black Wall Street's Resource Membership can help you access resources, mentorship, and a supportive community that will guide you through the process of establishing and growing your business.",
    },
    {
      question: "Which membership level is best for me?",
      answer: [
        { text: "The Platform level membership is best for you if you’re looking to build your online presence to increase leads and sales." },
        { text: "The Resource level membership is for you if you’re searching for a wealth of resources (funding opportunities, exclusive events, business perks, and more) to help fuel your business growth. " },
        { text: "The Growth level membership is for you if you’re an ambitious entrepreneur ready to position yourself as a thought leader and scale your business with exclusive advertising and opportunities. " },
      ],
    }, 
    {
      question: "How can I list my business on your app/website?",
      answer: [
        { text: "You can sign up to list your business and join our network " },
        { text: "here", link: "https://join.obws.com/#membershipSection" },
        { text: "." },
      ],
    },
    {
      question: "What happens after I join?",
      answer: "Once you join, you’ll receive an email with immediate access to your membership benefits, including the OBWS resources, instructions for creating your storefront, and the community platform where you can engage with other Black business owners. You'll also receive regular updates and invitations to exclusive events and programs.",
    },
    {
      question: "Do you offer advertising?",
      answer: [
        { text: "Yes, we provide monthly advertising through our Growth membership! This includes a monthly Homepage Editor’s Pick feature on the OBWS app and website, a monthly newsletter feature, a monthly social media post on Instagram, Facebook, Twitter, TikTok, Threads, and LinkedIn, and more. If you are looking for a la carte advertising, you can head to " },
        { text: "ads.obws.com", link: "https://ads.obws.com/" },
        { text: " to find more info about our promo options/rates and select what you would like to move forward with. " },
      ],
    },
    {
      question: "Can I cancel my membership?",
      answer:
        "Yes, while we’d love for you to stay, there are no fees or penalties for canceling your subscription at any time. If your subscription is canceled, you'll still be able to use your account until the end of your current subscription period.",
    },
    {
      question: "I have another question that isn't addressed here. Where can I receive support?",
      answer: [
        { text: "If you have additional questions, feel free to reach out to our support team at " },
        { text: "info@officialblackwallstreet.com", link: "mailto:info@officialblackwallstreet.com" },
      ],
    },
  ];

  return (
    <div className="faqouter">
      <Container>
        <div className="text-light ">
          <Row>
            <Col sm={7} className="mx-auto mt-2 mb-3">
              <div className="text-center text-dark py-5 faq-heading">
                <h1 className="headingfont">Frequently Asked Questions</h1>
              </div>

              <Accordion defaultActiveKey="0">
  {faqItems.map((item, index) => (
    <Accordion.Item
      className="mb-4 bg-transparent text-light border-bottom border-0 border-white"
      key={index}
      eventKey={index.toString()}
    >
      <Accordion.Header className="bg-transparent text-dark px-0">
        {item.question}
      </Accordion.Header>
      <Accordion.Body className="text-dark px-0 paragraphfont">
        {/* Check if the answer is an array */}
        {Array.isArray(item.answer) ? (
          item.question === "Which membership level is best for me?" ? (
            item.answer.map((part, idx) => (
              <div key={idx}>
                {part.link ? (
                  <a
                    href={part.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {part.text}
                  </a>
                ) : (
                  <span>{part.text}</span>
                )}
                {/* Adding a line break after each part, except for the last one */}
                {idx < item.answer.length - 1 && <br />}
              </div>
            ))
          ) : (
            item.answer.map((part, idx) => (
              <span key={idx}>
                {part.link ? (
                  <a
                    href={part.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {part.text}
                  </a>
                ) : (
                  part.text
                )}
              </span>
            ))
          )
        ) : (
          <span>{item.answer}</span>
        )}
      </Accordion.Body>
    </Accordion.Item>
  ))}
</Accordion>
			<div className="scriptside text-center">
                <a
                  href="#membershipSection" rel="noopener noreferrer"
                >
                  <Button className="my-3 text-white">
                    Join <b>OBWS</b> <img src={arrow} alt="" />
                  </Button>
                </a>
              </div>

            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Faq;