import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import arrow from "../assets/arrow.svg";

const PackageCards = () => {
  return (
    <Container>
      <div id="membershipSection" className="card-set">
        <Col xs={11} className="mx-auto py-5 ">
          <Row className="py-5">
            <Col xs={12} lg={4}>
              <Card className="pricing rounded-5 my-2">
                <div className="text-center p-2">
                  <p className="paragraphfont">Build your online presence</p>
                  <h1 className="platformtext">Platform</h1>
                  <h3 className="headingfont">
                    <span> $35/MONTH </span>OR $350/YEAR
                  </h3>
                </div>
                <div className="p-3 Poppins">
                  <ul>
                    <li> Access to the OBWS Private Membership club</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li> Create a landing page for your business</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li> Add up to 5 products or services to your page</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li> Reply to your page & product reviews</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>
                      Social media feature in new member round up
                      <br />
                      <b>(non-member cost: $150)</b>
                    </li>
                    <div
                      className="mb-2 line-425 px-2"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>
                      Newsletter feature in new member round up{" "}
                      <br />
                      <b>(non-member cost: $150)</b>
                    </li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li> Access to OBWS virtual and in person member events</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                  </ul>
                </div>
                <div className="scriptside text-center">
                  <a
                    href="https://ads.obws.com/platform "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="my-3 w-75 text-white">
                      Join <b>Now</b> <img src={arrow} alt="" />
                    </Button>
                  </a>
                </div>
              </Card>
            </Col>

            <Col xs={12} lg={4}>
              <Card className="pricing rounded-5 my-2">
                <div className="text-center p-2">
                  <p className="paragraphfont">Unlock funding and resources</p>
                  <h1 className="platformtext">Resource</h1>
                  <h3 className="headingfont">
                    <span> $97/MONTH </span>OR $999/YEAR
                  </h3>
                </div>
                <div className="p-3 Poppins">
                  <b>Everything in Platform, plus...</b>
                  <ul>
                    <li>Access to the OBWS Resource Suite featuring…</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>List of over $1M in funding opportunities</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>OBWS and partner discounts and perks</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>Masterclass replays with industry experts</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>

                    <li>List of conferences & events for founders</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>Comprehensive courses on grant writing, and more</li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                  </ul>
                </div>
                <div className="scriptside text-center">
                  <a
                    href="https://ads.obws.com/resource"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="my-3 w-75 text-white">
                      Join <b>OBWS</b> <img src={arrow} alt="" />
                    </Button>
                  </a>
                </div>
              </Card>
            </Col>

            <Col xs={12} lg={4}>
              <Card className="pricing rounded-5 my-2 bestvalue">
                <div className="growth text-center py-3 ">
                  <b>Best Value</b>
                </div>
                <div className="text-center p-2">
                  <p className="paragraphfont">Maximize your reach & scale</p>
                  <h1 className="platformtext">Growth</h1>
                  <h3 className="headingfont">
                    <span> $297/MONTH </span>OR $2,999/YEAR
                  </h3>
                </div>
                <div className="p-3 Poppins">
                  <b>Everything in Platform & Resource, plus…</b>
                  <ul>
                    <li>
                      Monthly homepage Editor's Pick feature{" "}
                      <br />
                      <b>(non-member cost: $100)</b>
                    </li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>
                      Monthly newsletter highlight{" "}
                      <br />
                      <b>(non-member cost: $150)</b>
                    </li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>

                    <li>
                      <span style={{ paddingLeft: '4px' }}>Monthly post on all OBWS social media pages</span>
                      <br />
                      <b>(non-member cost: $550)</b>
                    
                    </li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                    <li>
                    {" "}1 Interview each quarter {" "}
                    <br />
                      <b>(non-member cost: 750)</b>
                    </li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>

                    <li>
                    Monthly group coaching with industry experts
                    </li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>

                    <li>
                      First access to OBWS campaigns, events, & offers
                    </li>
                    <div
                      className="mb-2 line-425"
                      style={{ border: "1px solid gray" }}
                    ></div>
                  </ul>
                </div>
                <div className="scriptside text-center">
                  <a
                    href="https://ads.obws.com/growth"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="my-3 w-75 text-white">
                      Join <b>OBWS</b> <img src={arrow} alt="" />
                    </Button>
                  </a>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </div>
    </Container>
  );
};

export default PackageCards;
